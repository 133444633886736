<template>
  <div class="wrap">
    <el-card shadow="always">
      <div class="corpo-card" v-loading="loading">
        <div class="topo-card">
          <img src="../assets/konnect-chat.svg" alt="logo" width="100" class="logo-circulo" />
          <p v-if="token && usuario.email">
            <strong>{{ usuario.email }}</strong>
          </p>
          <p>Para acessar o <b>Konnect</b> digite sua senha</p>
        </div>
        <el-form :model="usuario" :rules="rules" ref="form-login" @submit.prevent="logar">
          <el-form-item prop="email" v-if="!token">
            <el-input placeholder="Digite seu email" type="email" v-model="usuario.email"></el-input>
          </el-form-item>
          <el-form-item prop="senha">
            <el-input placeholder="Digite sua senha" type="password" v-model="usuario.senha"></el-input>
          </el-form-item>
          <el-form-item id="esqueci">
            <a :href="`${webapp}/senha/recuperar`" target="_self">Esqueci minha senha</a>
          </el-form-item>
          <el-button tabindex="3" type="primary" @click.prevent="logar" native-type="submit">
            Entrar
            <i class="fas fa-arrow-right left-icon"></i>
          </el-button>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import JwtDecode from "jwt-decode";
import AuthService from "../services/AuthService.js";
import KonnectManagerService from "../services/KonnectManagerService";
import { webapps } from "@/assets/recursosKlausConfig";

export default {
  name: "Login",
  components: {},

  data() {
    return {
      loading: false,
      token: "",
      webapp: webapps.KLAUS_WEBAPP.baseUrl,
      usuario: {
        email: "",
        senha: "",
      },
      rules: {
        email: [
          {
            type: "email",
            message: "Digite um email válido.",
            trigger: "blur",
          },
        ],
        senha: [
          {
            type: "password",
            message: "Digite uma senha válida.",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (window.localStorage.token) this.$router.push("/");
    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      this.decodificarToken(this.token);
    }
  },

  methods: {
    decodificarToken() {
      try {
        let tokenDecodificado = JwtDecode(this.token);
        console.log(tokenDecodificado);
        this.usuario.email = tokenDecodificado.email;
      } catch (e) {
        this.token = "";
        this.$router.push("/login");
      }
    },
    logar(e) {
      e.preventDefault();
      e.stopPropagation();
      this.loading = true;
      AuthService.login(this.usuario)
        .then((r) => {
          window.localStorage.setItem("token", r.data.authenticationToken);
          this.getUsuarioLogado();
        })
        .catch((e) => {
          console.error(e);
          if (e.response.status === 401 || e.response.status === 422) {
            this.$message({
              message: "Dados de login incorretos.",
              type: "error",
            });
          } else {
            this.$message({
              message: "Ocorreu algum erro ao entrar.",
              type: "error",
            });
          }
        })
        .finally(() => (this.loading = false));
    },

    getUsuarioLogado() {
      AuthService.usuarioLogado()
        .then((r) => {
          KonnectManagerService.permissaoUsuarioLogado()
            .then(() => {
              window.localStorage.setItem("usuario", JSON.stringify(r.data));
              if (window.localStorage.getItem("token")) this.$router.push("/");
            })
            .catch((e) => {
              if (e.response.status == 403) {
                this.$message({
                  message: e.response.data,
                  type: "warning",
                })
              } else {
                this.$message({
                  message: "Você não tem acesso ao Konnect, ainda estamos em BETA, em breve todos poderão adquirir uma licença.",
                  type: "warning",
                });
              }
            });
        })
        .catch((e) => console.error(e));
    },
  },
};
</script>
<style scoped>
.wrap {
  height: 100vh;
  background: #1488cc;
  background: -webkit-linear-gradient(to right, #2b32b2, #1488cc);
  background: linear-gradient(to right, #2b32b2, #1488cc);
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-circulo {
  width: 50px;
  margin-bottom: 25px;
}

.el-input {
  margin-top: 10px;
}

@media (max-width: 500px) {
  .el-card {
    box-sizing: border-box;
    text-align: center;
    word-break: keep-all;
    width: 90%;
  }

  .corpo-card {
    padding: 30px 0;
    min-width: 250px;
  }
}

@media (min-width: 500px) {
  .el-card {
    box-sizing: border-box;
    text-align: center;
    word-break: keep-all;
    width: 400px;
  }

  .corpo-card {
    padding: 50px 20px;
  }
}
</style>